//slide-in-left
.slide-in-left-enter {
    transform: translateX(-100%);
  }
  
  .slide-in-left-enter-active {
    transform: translateX(0);
    opacity: 1;
    transition: all 200ms;
  }
  
  .slide-in-left-exit {
    transform: translateX(0%);
    opacity: 1;
  }
  
  .slide-in-left-exit-active {
    transform: translateX(-100%);
    opacity: 0;
    transition: all 200ms;
  }
  
  //modal-slide-in-bottom
  .modal-enter {
    transform: translateY(160px);
    opacity: 0;
  }
  
  .modal-enter-active {
    transform: translateY(0);
    opacity: 1;
    transition: all 200ms;
  }
  
  .modal-exit {
    transform: translateY(0);
    opacity: 1;
  }
  
  .modal-exit-active {
    transform: translateY(160px);
    opacity: 0;
    transition: all 200ms;
  }