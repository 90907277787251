.btn {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 400;
    color: #212529;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  
    svg {
      vertical-align: middle;
    }
  
    &.primary {
      color: var(--btn-primary-txt);
      background-color: #9b8b38;
      border-color: #9b8b38;
      &:hover {
        color: var(--btn-primary-txt);
        background-color: #edd556;
        border-color: #edd556;
      }
    }
  }