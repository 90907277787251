.light {
  --txt: #fff;
  --txt-secondary: #edd556;
  --txt-sec-darcker: #edd556;
  --bg-layout-home: linear-gradient(to left, RGB(89 89 89), RGB(17 16 18));
  --bg-home-section-main: linear-gradient(to bottom left, rgba(17, 16, 16, 0.678), rgba(12, 10, 22, 0.863)), url(../assets/home-bg.jpg);
  --bg-layout-base: linear-gradient(to left, RGB(89 89 89), RGB(17 16 18));
  --bg-primary: #181a27;
  --bg: rgb(89, 89, 89);
  --bg-active: rgba(89 89 89);
  --bg-header: rgba(17 16 18);
  --bg-footer: #0a0416;
  --backdrop: rgba(0, 0, 0, 0.75);
  --nav-active: #edd556;
  --border-primary: #edd556;
  --logo-primary: #edd556;
  --social-icon: #edd556;
  --social-shadow: #edd556;
  --preloader: #0c0513;
  --bg-modal: #100D19;
  --modal-border: 1px solid rgba(0,0,0,.125);
  --modal-close: #edd556;
  --modal-close-icon: #fff;
  --drawer-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  --btn-primary-txt: #fff;
  --tags-txt: #fff;
  --toggle-theme: #fff;
  }
  
  .dark {
    --txt: #fff;
    --txt-secondary: #edd556;
    --txt-sec-darcker: #edd556;
    --bg-layout-home: linear-gradient(to left, RGB(89 89 89), RGB(17 16 18));
    --bg-home-section-main: linear-gradient(to bottom left, rgba(17, 16, 16, 0.678), rgba(12, 10, 22, 0.863)), url(../assets/home-bg.jpg);
    --bg-layout-base: linear-gradient(to left, RGB(89 89 89), RGB(17 16 18));
    --bg-primary: #181a27;
    --bg: rgb(89, 89, 89);
    --bg-active: rgba(89 89 89);
    --bg-header: rgba(17 16 18);
    --bg-footer: #0a0416;
    --backdrop: rgba(0, 0, 0, 0.75);
    --nav-active: #edd556;
    --border-primary: #edd556;
    --logo-primary: #edd556;
    --social-icon: #edd556;
    --social-shadow: #edd556;
    --preloader: #0c0513;
    --bg-modal: #100D19;
    --modal-border: 1px solid rgba(0,0,0,.125);
    --modal-close: #edd556;
    --modal-close-icon: #fff;
    --drawer-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
    --btn-primary-txt: #fff;
    --tags-txt: #fff;
    --toggle-theme: #fff;
  }